<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <classroom-student
            :resources="resources"
            @updateDate="changeDate"
            @refresh="refresh()"
          ></classroom-student>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import ClassroomStudent from "../../Components/Attendance/ClassroomStudent.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import moment from 'moment'

export default {
  props: ["class"],

  components: {
    BaseLayout,
    ClassroomStudent,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      title: "Attendence",
      backLink: "setup",
      reloadable: true,
      date: moment().format('YYYY-MM-DD'),
    };
  },

  computed: {
    resources() {
      return this.$store.getters["attendanceClassroom/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async sync() {
      const title = this.$store.getters["attendanceClassroom/title"];
      this.title = "Attendence: " + title;
    },
    async fetch(refresh) {
      await this.$store.dispatch("attendanceClassroom/resources", {
        resourceId: this.class,
        refresh: refresh,
        attendance_date: this.date,
      });
      this.sync();
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("attendanceClassroom/resources", {
        resourceId: this.class,
        attendance_date: this.date,
        refresh: true,
      });
      if (event) {
        event.target.complete();
      }
      this.sync();
    },
    changeDate(date) {
      this.date = moment(date).format('YYYY-MM-DD')
      this.refresh();
    },
  },

  created() {
    this.fetch(true);
  },
};
</script>

<style scoped>
</style>
