<template>
  <ion-item class="ion-margin-bottom" lines="none">
    <ion-label position="floating">Attendance date</ion-label>
    <ion-datetime
      type="text"
      v-model="date"
      @ionChange="setDate()"
    ></ion-datetime>
  </ion-item>

  <ion-list v-if="resources.data.students != null" class="ion-margin-top">
    <ion-item v-for="item in resources.data.students.data" :key="item.id">
      <ion-label>
        <h2>
          <ion-note>{{ item.roll_no }}</ion-note>
          {{ item.user.user_detail.name }}
        </h2>
      </ion-label>
      <ion-select
        placeholder="Attendance"
        interface="popover"
        @ionChange="setAttendance(item.user.id, $event.target.value)"
        :value="initAttendance(item)"
      >
        <ion-select-option :value="2">Present</ion-select-option>
        <ion-select-option :value="3">Absent</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No students found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonDatetime,
  IonLabel,
  IonItem,
  IonNote,
  IonSelect,
  IonSelectOption,
  alertController,
} from "@ionic/vue";

import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  emits: ["updateDate", "refresh"],

  components: {
    IonList,
    IonListHeader,
    IonDatetime,
    IonLabel,
    IonItem,
    IonNote,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      date: new Date().toISOString(),

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    setDate() {
      this.$emit("updateDate", this.date);
    },

    async setAttendance(user_id, val) {
      // const attendances = item.user.attendances;

      const data = {
        user_id: user_id,
        attendance_state_id: val,
        attendance_date: moment(this.date).format("YYYY-MM-DD"),
      };

      // let attendance_id = null;

      // if (Array.isArray(attendances) && attendances.length > 0) {
      //   attendance_id = attendances[0].id;
      //   await this.updateAttendance(attendance_id, data);
      // } else {
      //   await this.createAttendance(data);
      // }

      // this.$emit("refresh")

      try {
        let res = await this.$store.dispatch("attendance/createResource", data);
        const resourceId = this.resources.data.sectionStandard.id;
        res.resourceId = resourceId;
        await this.$store.dispatch("attendanceClassroom/updateRecord", res);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    // async createAttendance(data) {
    //   try {
    //     await this.$store.dispatch("attendance/createResource", data);
    //   } catch (error) {
    //     this.setErrorResponse(error);
    //     const alertDialog = await this.alerter(
    //       this.xhrError.header,
    //       this.xhrError.message
    //     );
    //     alertDialog.present();
    //   }
    // },

    // async updateAttendance(id, data) {
    //   try {
    //     await this.$store.dispatch("attendance/updateResource", { id, data });
    //   } catch (error) {
    //     this.setErrorResponse(error);
    //     const alertDialog = await this.alerter(
    //       this.xhrError.header,
    //       this.xhrError.message
    //     );
    //     alertDialog.present();
    //   }
    // },

    initAttendance(item) {
      const attendances = item.user.attendances;
      let attendance = 1;

      if (Array.isArray(attendances) && attendances.length > 0) {
        attendance = attendances[0].attendance_state_id;
      }
      return attendance;
    },
  },
};
</script>
